var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      {
        ref: "g-gantt-row",
        staticClass: "g-gantt-row",
        style: { height: `${_vm.$parent.rowHeight}px` },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "g-gantt-row-label", style: _vm.rowLabelStyle },
        [
          _vm._t(
            "default",
            function () {
              return [_vm._v(" " + _vm._s(_vm.rowId) + " ")]
            },
            { id: _vm.rowId }
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          ref: "barContainer",
          staticClass: "g-gantt-row-bars-container",
          on: {
            dragover: function ($event) {
              return _vm.onDragover($event)
            },
            dragleave: function ($event) {
              return _vm.onDragleave($event)
            },
            drop: function ($event) {
              return _vm.onDrop($event)
            },
            dblclick: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.onDoubleClick($event)
            },
            mouseover: function ($event) {
              return _vm.onMouseover()
            },
            mouseleave: function ($event) {
              return _vm.onMouseleave()
            },
          },
        },
        _vm._l(_vm.bars, function (bar, index) {
          return _c("g-gantt-bar", {
            key: `ganttastic_bar_${index}`,
            ref: "ganttBar",
            refInFor: true,
            attrs: {
              bar: bar,
              "bar-container": _vm.barContainer,
              "all-bars-in-row": _vm.bars,
              "active-bars": _vm.activeBars,
              "row-id": _vm.rowId,
              "is-immobile": _vm.isImmobile,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "bar-label",
                  fn: function ({ bar }) {
                    return [_vm._t("bar-label", null, { bar: bar })]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }