<template>
  <div
    ref="g-gantt-row"
    class="g-gantt-row"
    :style="{ height: `${$parent.rowHeight}px` }"
    v-on="$listeners"
  >
    <div class="g-gantt-row-label" :style="rowLabelStyle">
      <slot :id="rowId">
        {{ rowId }}
      </slot>
    </div>
    <div
      ref="barContainer"
      class="g-gantt-row-bars-container"
      @dragover="onDragover($event)"
      @dragleave="onDragleave($event)"
      @drop="onDrop($event)"
      @dblclick.self="onDoubleClick($event)"
      @mouseover="onMouseover()"
      @mouseleave="onMouseleave()"
    >
      <g-gantt-bar
        v-for="(bar, index) in bars"
        :key="`ganttastic_bar_${index}`"
        ref="ganttBar"
        :bar="bar"
        :bar-container="barContainer"
        :all-bars-in-row="bars"
        :active-bars="activeBars"
        :row-id="rowId"
        :is-immobile="isImmobile"
      >
        <template #bar-label="{ bar }">
          <slot name="bar-label" :bar="bar" />
        </template>
      </g-gantt-bar>
    </div>
  </div>
</template>

<script>
import { getDateToUnix, nowUnix } from '@/core'
import GGanttBar from './GGanttBar.vue'
import moment from '@/core/date'

export default {
  name: 'GGanttRow',

  components: {
    GGanttBar,
  },

  inject: [
    'getThemeColors',
    'getTimeCount',
    'getChartStart',
    'getChartEnd',
    'getDefaultBarLength',
    'getTimeUnit',
    'getTimeFormat',
  ],

  props: {
    label: { type: String, default: 'Row' },
    rowId: { type: [Number, String], default: null },
    bars: { type: Array, default: () => [] },
    highlightOnHover: { type: Boolean },
    activeBars: {
      type: Array,
      default: () => [],
    },
    isImmobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      barContainer: {},
      timeUnit: this.getTimeUnit(),
      timeFormat: this.getTimeFormat(),
    }
  },

  computed: {
    rowLabelStyle() {
      return {
        width: `${this.$parent.rowLabelWidth}px`,
        // height: `${this.$parent.rowHeight}px`,
        background: this.$parent.themeColors.ternary,
        color: this.$parent.themeColors.text,
      }
    },
  },

  watch: {
    '$parent.rowLabelWidth'() {
      this.barContainer = this.$refs.barContainer.getBoundingClientRect()
    },
  },

  mounted() {
    this.barContainer = this.$refs.barContainer.getBoundingClientRect()
    window.addEventListener('resize', this.onWindowResize)
  },

  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },

  methods: {
    onDragover(e) {
      e.preventDefault() // enables dropping content on row

      if (this.highlightOnHover) {
        this.$refs['g-gantt-row'].style.backgroundColor =
          this.getThemeColors().hoverHighlight
      }
    },

    onDragleave() {
      this.$refs['g-gantt-row'].style.backgroundColor = null
    },

    onDrop(e) {
      let barContainer = this.$refs.barContainer.getBoundingClientRect()
      let xPos = e.clientX - barContainer.left
      let timeDiffFromStart = (xPos / barContainer.width) * this.getTimeCount()
      let time = moment(this.getChartStart()).add(
        timeDiffFromStart,
        this.timeUnit,
      )
      let bar = this.bars.find(bar =>
        time.isBetween(
          bar[this.$parent.barStartKey],
          bar[this.$parent.barEndKey],
        ),
      )

      this.$emit('drop', { event: e, bar, time: time.format(this.timeFormat) })
    },

    onDoubleClick(e) {
      if (!this.$parent.mayAdd || this.rowId === 'nullLine') {
        return
      }
      let barContainer = this.$refs.barContainer.getBoundingClientRect()
      let xPos = e.clientX - barContainer.left
      let timeDiffFromStart = Math.floor(
        (xPos / barContainer.width) * this.getTimeCount(),
      )
      let time = moment(this.getChartStart()).add(
        timeDiffFromStart,
        this.timeUnit,
      )
      let bar = {}

      bar[this.$parent.barStartKey] = time.format()
      bar[this.$parent.barEndKey] = time
        .add(this.getDefaultBarLength(), this.timeUnit)
        .format()

      if (getDateToUnix(bar.time_to) < nowUnix()) {
        return
      }

      bar.ganttBarConfig = { handles: true }
      // eslint-disable-next-line vue/no-mutating-props
      this.bars.push(bar)
      // eslint-disable-next-line vue/no-mutating-props
      this.bars.sort((first, second) =>
        moment(first[this.$parent.barStartKey]).diff(
          second[this.$parent.barStartKey],
        ),
      )

      this.$emit('addNewBar', { rowId: this.rowId, bars: this.bars, bar })
    },

    onMouseover() {
      if (this.highlightOnHover) {
        this.$refs['g-gantt-row'].style.backgroundColor =
          this.getThemeColors().hoverHighlight
      }
    },

    onMouseleave() {
      this.$refs['g-gantt-row'].style.backgroundColor = null
    },

    onWindowResize() {
      // re-initialize the barContainer DOMRect variable, which will trigger re-rendering in the gantt bars
      if (this.$refs.barContainer) {
        this.barContainer = this.$refs.barContainer.getBoundingClientRect()
      }
    },
  },
}
</script>
